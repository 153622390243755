import types from 'actions/types';

import * as CreativesModel from 'models/creatives';

import { cdeebeeHelpers } from '@recats/cdeebee';

export const INITIAL_STATE: CreativesModel.ICreativeReducer = {
  ui: {
    toUnlikIDList: [],
  },
  filter: {
    campaignIDList: [],
    creativeIDList: [],
    reklIDList: [],
    hashList: [],
    creativeTypeList: [],
  },
};

export default function(state: CreativesModel.ICreativeReducer = INITIAL_STATE, action: any) {
  const { type, payload } = action;
  switch (type) {
    case types.CREATIVES_CHANGE_VALUE:
      return cdeebeeHelpers.batchingUpdate(state, payload.valueList, payload.preBatchingPath);
    case types.CREATIVES_RESET_TO_INITIAL:
      return INITIAL_STATE;
    default:
      return state;
  }
}
