import { Routes, Route } from 'react-router-dom';

import BaseRouter from 'components/BaseRouter';
import PrivateRouter from 'components/PrivateRouter';
//
import routes from 'constants/routes';
//
import * as M from './imports';

export default () => (
  <Routes>
    <Route
      path={routes.login}
      element={<BaseRouter><M.Auth /></BaseRouter>}
    />
    <Route
      path={routes.serviceConfirmPassword}
      element={<BaseRouter><M.ServiceRestorePassword /></BaseRouter>}
    />
    <Route
      path={`${routes.serviceNoUser}/:errorCode`}
      element={<BaseRouter noRedirect={true}><M.ServiceNoResponseUser /></BaseRouter>}
    />
    <Route
      path={routes.billingStatus}
      element={<BaseRouter noRedirect={true}><M.ServiceBillingStatus /></BaseRouter>}
    />
    {/* */}

    {/* campaigns */}
    <Route
      path={routes.campaigns}
      element={<PrivateRouter><M.CampaignIndex /></PrivateRouter>}
    />
    <Route
      path={`${routes.campaignsCreate}/:menuState`}
      element={
        <PrivateRouter permission='campaigns.create'>
          <M.CampaignCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.campaignsDetail}/:campaignIDList`}
      element={<PrivateRouter><M.CampaignDetail /></PrivateRouter>}
    />
    <Route
      path={`${routes.campaignsEdit}/:campaignID/:menuState`}
      element={
        <PrivateRouter permission='campaigns.campaignSettingsView'>
          <M.CampaignEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] campaigns */}

    {/* creatives */}
    <Route
      path={routes.creatives}
      element={<PrivateRouter><M.CreativesIndex /></PrivateRouter>}
    />
    <Route
      path={`${routes.advertiser}/:reklID${routes.creatives}`}
      element={<PrivateRouter><M.CreativesIndexByRekl /></PrivateRouter>}
    />
    <Route
      path={`${routes.campaign}/:campaignID${routes.creatives}`}
      element={<PrivateRouter><M.CreativesIndexByCampaign /></PrivateRouter>}
    />
    <Route
      path={`${routes.creativesCreate}/:reklID`}
      element={<PrivateRouter permission='creatives.create'><M.CreativesCreate /></PrivateRouter>}
    />
    <Route
      path={`${routes.creativesEdit}/:creativeID`}
      element={<PrivateRouter><M.CreativesEdit /></PrivateRouter>}
    />
    <Route
      path={routes.creativesApprove}
      element={
        <PrivateRouter permission='creatives.approveAction'>
          <M.CreativeApprove />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.creativesPreview}/:creativeID`}
      element={<PrivateRouter><M.CreativePreview /></PrivateRouter>}
    />
    {/* [[#END]] creatives */}

    {/* user */}
    <Route
      path={routes.userSettings}
      element={<PrivateRouter><M.UserSettings /></PrivateRouter>}
    />
    {/* [[#END]] user */}

    {/* Advertisers */}
    <Route
      path={routes.advertisers}
      element={
        <PrivateRouter permission='advertisers.routerList'>
          <M.Advertisers />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.advertiserCreate}
      element={
        <PrivateRouter permission='advertisers.create'>
          <M.AdvertiserCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.advertiser}/:reklID`}
      element={
        <PrivateRouter permission='advertisers.routerDetail'>
          <M.AdvertisersDetail />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Advertisers */}

    {/* Markup */}
    <Route
      path={routes.markups}
      element={
        <PrivateRouter permission='markups.routerList'>
          <M.Markups />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.markupsCreate}
      element={
        <PrivateRouter permission='markups.create'>
          <M.MarkupsCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.markupsEdit}/:markupID`}
      element={
        <PrivateRouter permission='markups.edit'>
          <M.MarkupsEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Markup */}

    {/* Segment */}
    <Route
      path={routes.segments}
      element={
        <PrivateRouter permission='segments.view'>
          <M.Segments />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Segment */}

    {/* Reports */}
    <Route
      path={routes.reports}
      element={
        <PrivateRouter permission='reports.routerList'>
          <M.Reports />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Reports */}

    {/* History */}
    <Route
      path={`${routes.history}/:type/:elementID`}
      element={
        <PrivateRouter permission='history.routerList'>
          <M.History />
        </PrivateRouter>
      }
    />
    {/* [[#END]] History */}

    {/* Model */}
    <Route
      path={routes.modelTasks}
      element={
        <PrivateRouter permission='models.modelTasksPage'>
          <M.ModelTasks />
        </PrivateRouter>
      }
    >
      <Route
        path=':bundleID'
        element={
          <PrivateRouter permission='models.modelTasksPage'>
            <M.ModelTasks />
          </PrivateRouter>
        }
      />
    </Route>
    {/* [[#END]] Model */}

    {/* Product */}
    <Route
      path={`${routes.productEdit}/:productID/:menuState`}
      element={
        <PrivateRouter permission='product.edit'>
          <M.ProductEdit />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.productCreate}/:menuState`}
      element={
        <PrivateRouter permission='product.create'>
          <M.ProductCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Product */}

    {/* Organization */}
    <Route
      path={routes.organizations}
      element={
        <PrivateRouter permission='organization.organizationList'>
          <M.OrganizationIndex />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.organizationsEdit}/:organizationID`}
      element={
        <PrivateRouter permission='organization.organizationEdit'>
          <M.OrganizationShow />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.organizationsView}/:organizationID`}
      element={
        <PrivateRouter permission='organization.organizationView'>
          <M.OrganizationShow />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.organizationCreate}
      element={
        <PrivateRouter permission='organization.organizationCreate'>
          <M.OrganizationCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Organization */}

    {/* Bundles */}
    <Route
      path={routes.bundles}
      element={
        <PrivateRouter permission='bundles.menu'>
          <M.Bundles />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.bundleCreate}/:reklID`}
      element={
        <PrivateRouter permission='bundles.menu'>
          <M.BundleCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Bundles */}

    {/* Advertiser Bundle */}
    <Route
      path={`${routes.advertiserBundle}/:reklBundleID`}
      element={
        <PrivateRouter permission='reklBundles.menu'>
          <M.AdvertiserBundleIndex />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.advertiserBundleEdit}/:reklBundleID`}
      element={
        <PrivateRouter permission='reklBundles.edit'>
          <M.AdvertiserBundleEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Advertiser Bundle */}

    {/*Billing*/}
    <Route
      path={routes.billing}
      element={
        <PrivateRouter permission='billing.billingPage'>
          <M.BillingIndex />
        </PrivateRouter>
      }
    />
    {/*[[#END]] Billing*/}

    {/*Summary*/}
    <Route
      path={routes.summary}
      element={
        <PrivateRouter permission='summary.view'>
          <M.SummaryIndex />
        </PrivateRouter>
      }
    />
    {/*[[#END]] Summary*/}

    <Route
      path={routes.usersManager}
      element={
        <PrivateRouter permission='user.routerManager'>
          <M.UsersManager />
        </PrivateRouter>
      }
    />

    <Route
      path={'/*'}
      element={<PrivateRouter><M.NotFound /></PrivateRouter>}
    />
  </Routes>
);
