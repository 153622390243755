import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import orderBy from 'lodash-es/orderBy';

import { EnumListName } from 'constants/enum';

import { getActiveUISession, IInObjectDto } from 'helpers/utils';

import {
  EnumUserRole,
  OrganizationDto,
  ProductDto,
  ProfileDto,
  ReklDto,
  SegmentDto,
  UserDto,
  ReportTaskPrototypeDto,
  MLearnTaskDto,
  PredictorModelDto,
  MarkupDto,
  HistoryLogDto,
  CreativeDto,
  ResourceDto,
  CampaignDto,
  CreativeResourceDto,
  ExchangeDto,
  ExchangeLossReasonDto,
  RejectDto,
  CampaignStatsGroupResultDto,
  ConnectionTypeDto,
  IabCategoryDto,
  CountryDto,
  LanguageDto,
  SspDto,
  TrackerDto,
  ReklBundleDto,
  BundleDto,
  OrganizationPaymentDto,
  EnumOrderingDirection,
  GenreDto,
  SessionDto,
  TimeZoneDto,
} from 'models/objects';
import { IStorage } from 'models/utils';

import { IReturnPermission, permissionObjectChecker } from 'helpers/permission';

export interface ICdeebeeRootState {
  [EnumListName.sessionList]: IInObjectDto<SessionDto>
  [EnumListName.productList]: IInObjectDto<ProductDto>;
  [EnumListName.reklList]: IInObjectDto<ReklDto>;
  [EnumListName.segmentList]: IInObjectDto<SegmentDto>;
  [EnumListName.userList]: IInObjectDto<UserDto>;
  [EnumListName.reportTaskPrototypeList]: IInObjectDto<ReportTaskPrototypeDto>;
  [EnumListName.organizationList]: IInObjectDto<OrganizationDto>;
  [EnumListName.mLearnTaskList]: IInObjectDto<MLearnTaskDto>;
  [EnumListName.predictorModelList]: IInObjectDto<PredictorModelDto>;
  [EnumListName.markupList]: IInObjectDto<MarkupDto>;
  [EnumListName.historyLogList]: IInObjectDto<HistoryLogDto>;
  [EnumListName.creativeList]: IInObjectDto<CreativeDto>;
  [EnumListName.resourceList]: IInObjectDto<ResourceDto>;
  [EnumListName.campaignList]: IInObjectDto<CampaignDto>;
  [EnumListName.exchangeList]: IInObjectDto<ExchangeDto>;
  [EnumListName.rejectList]: IInObjectDto<RejectDto>;
  [EnumListName.exchangeLossReasonList]: IInObjectDto<ExchangeLossReasonDto>;
  [EnumListName.connectionTypeList]: IInObjectDto<ConnectionTypeDto>;
  [EnumListName.countryList]: IInObjectDto<CountryDto>;
  [EnumListName.iabCategoryList]: IInObjectDto<IabCategoryDto>;
  [EnumListName.languageList]: IInObjectDto<LanguageDto>;
  [EnumListName.sspList]: IInObjectDto<SspDto>;
  [EnumListName.reklBundleList]: IInObjectDto<ReklBundleDto>;
  [EnumListName.bundleList]: IInObjectDto<BundleDto>;
  [EnumListName.organizationPaymentList]: IInObjectDto<OrganizationPaymentDto>;
  [EnumListName.genreList]: IInObjectDto<GenreDto>;
  [EnumListName.trackerList]: IInObjectDto<TrackerDto>;
  [EnumListName.timeZoneList]: IInObjectDto<TimeZoneDto>;
  RAW_GroupedStats: {
    groupedStats: CampaignStatsGroupResultDto;
  }
}

// Product
export const useFindProductsByRekl = (reklID: number) => {
  return useSelector<IStorage, ProductDto[]>(({ cdeebee }) => {
    const values = Object.values(cdeebee.productList || {});
    return values.filter(q => cdeebee.reklBundleList[q.reklBundleID].reklID === reklID);
  });
};
export const useFindProductByReklBundleID = (reklBundleIDList: number[]) => (
  useSelector<IStorage, ProductDto[]>(({ cdeebee: { productList } }) => Object.values(productList || {}).filter(q => reklBundleIDList.includes(q.reklBundleID)))
);
export const useFindProductByID = (productID: number | undefined) => (
  useSelector<IStorage, ProductDto | null>(({ cdeebee: { productList } }) => productList && productID ? productList[productID] : null)
);
export const useFindProductsByID = (productIDList: number[] = []) => (
  useSelector<IStorage, ProductDto[]>(({ cdeebee: { productList } }) => Object.values(productList || {}).filter(q => productIDList.includes(q.productID)))
);
// [[#end]] Product

// Creatives
export const useCreativeList = () => (
  useSelector<IStorage, IInObjectDto<CreativeDto>>(({ cdeebee: { creativeList } }) => creativeList)
);
export const useFindCreativeByID = (creativeID: number | undefined) => (
  useSelector<IStorage, CreativeDto | null>(({ cdeebee: { creativeList } }) => (creativeList && creativeID) ? creativeList[creativeID] : null)
);
// [[#end]] Creatives

// Rekl
export const useFindReklByID = (reklID: number | undefined) => (
  useSelector<IStorage, ReklDto | null>(({ cdeebee: { reklList } }) => (reklList && reklID) ? reklList[reklID] : null)
);
export const useReklList = () => (
  useSelector<IStorage, IInObjectDto<ReklDto>>(({ cdeebee: { reklList } }) => reklList)
);
// [[#end]] Rekl

// Profile
export const useUIExecutorSession = () => {
  return useSelector<IStorage, SessionDto>(({ cdeebee: { sessionList } }) => getActiveUISession(sessionList));
};

// in case with multiple sessions this method will return not admin profile
// if yuo need to get admin profile use useAdminProfile
export const useProfile = () => {
  const session = useUIExecutorSession();
  return useMemo<ProfileDto>(() => session?.profile, [session?.profile]);
};

// this method will return admin profile on null if user not admin and not have admin session
export const useAdminProfile = () => (
  useSelector<IStorage, ProfileDto | undefined>(({ cdeebee: { sessionList } }) => (
    Object.values(sessionList || {}).find(q => q.profile.userRole === EnumUserRole.administrator)?.profile
  ))
);

export const useProfileFavoriteReklIDList = () => {
  const profile = useProfile();
  return useMemo(() => profile.favoriteReklIDList, [profile?.favoriteReklIDList]);
};

export const useProfilePermission = () => {
  const profile = useProfile();
  return useMemo<IReturnPermission>(() => permissionObjectChecker(profile), [profile]);
};

export const useCheckProfileRole = (role: EnumUserRole) => {
  const profile = useProfile();
  return useMemo(() => profile.userRole === role, [profile.userRole, role]);
};
// [[#end]] Profile

// Segment
export const useFindSegment = (segmentID: number) => (
  useSelector<IStorage, SegmentDto>(({ cdeebee: { segmentList } }) => segmentList[segmentID])
);
// [[#end]] Segment

// Resources
export const useFindResourceByID = (resourceID?: number) => (
  useSelector<IStorage, ResourceDto | null>(({ cdeebee: { resourceList } }) => (resourceList && resourceID) ? resourceList[resourceID] : null)
);
export const useResourceList = () => (
  useSelector<IStorage, IInObjectDto<ResourceDto>>(({ cdeebee: { resourceList } }) => resourceList)
);
export const useFindResourceListByCreativeContent = (creativeResourceList: CreativeResourceDto[]) => {
  const resourceList = useSelector<IStorage, ResourceDto[]>(({ cdeebee: { resourceList } }) => creativeResourceList.map(q => resourceList[q.resourceID]));
  return useMemo(() => orderBy(resourceList, 'updatedAt', 'desc'), [resourceList]);
};
// Resources

// Campaign
export const useCampaignList = () => (
  useSelector<IStorage, IInObjectDto<CampaignDto>>(({ cdeebee: { campaignList } }) => campaignList)
);
export const useFindCampaignByID = (campaignID: number) => (
  useSelector<IStorage, CampaignDto | null>(({ cdeebee: { campaignList } }) => campaignList ? campaignList[campaignID] : null)
);
// [[#end]] Campaign

export const useRequestByApi = <T>(api: string, list: EnumListName) => (
  useSelector<{ requestManager: { requestByApiUrl: IInObjectDto<any> } }, T[]>(({ requestManager: { requestByApiUrl } }) => requestByApiUrl?.[api]?.[list]?.data || [])
);
export const useRequestByApiRaw = <T>(api: string, list: string): T | null => (
  useSelector<{ requestManager: { requestByApiUrl: IInObjectDto<any> } }, T | null>(({ requestManager: { requestByApiUrl } }) => requestByApiUrl?.[api]?.rawResponse?.[list] || null)
);

// User
export const useUserList = () => (
  useSelector<IStorage, IInObjectDto<UserDto>>(({ cdeebee: { userList } }) => userList)
);
// [[#end]] User

// History
export const useHistoryList = () => (
  useSelector<IStorage, IInObjectDto<HistoryLogDto>>(({ cdeebee: { historyLogList } }) => historyLogList)
);
// [[#end]] History

// Markup
export const useMarkupList = () => (
  useSelector<IStorage, IInObjectDto<MarkupDto>>(({ cdeebee: { markupList } }) => markupList)
);
export const useFindMarkupByID = (markupID: number | undefined) => (
  useSelector<IStorage, MarkupDto | null>(({ cdeebee: { markupList } }) => (markupList && markupID) ? markupList[markupID] : null)
);
// [[#end]] Markup

// Organization
export const useOrganization = () => {
  const profile = useProfile();
  return useFindOrganizationByID(profile.organizationID);
};
export const useOrganizationList = () => (
  useSelector<IStorage, IInObjectDto<OrganizationDto>>(({ cdeebee: { organizationList } }) => organizationList)
);
export const useFindOrganizationByID = (organizationID: number) => (
  useSelector<IStorage, OrganizationDto | null>(({ cdeebee: { organizationList } }) => organizationList ? organizationList[organizationID] : null)
);

export const useOrganizationPaymentList = (organizationID: number) => {
  const data = useSelector<IStorage, OrganizationPaymentDto[]>(({ cdeebee: { organizationPaymentList } }) => {
    const getValues = Object.values(organizationPaymentList || {});
    return organizationID ? getValues.filter(q => q.organizationID === +organizationID) : [];
  });

  return useMemo(() => orderBy(data, 'organizationPaymentID', EnumOrderingDirection.desc), [data]);
};
export const useOrganizationPaymentByID = (organizationPaymentID: number) => (
  useSelector<IStorage, OrganizationPaymentDto>(({ cdeebee: { organizationPaymentList } }) => organizationPaymentList[organizationPaymentID])
);
// [[#end]] Organization

// Reports
export const useReportTaskPrototypeList = () => (
  useSelector<IStorage, IInObjectDto<ReportTaskPrototypeDto>>(({ cdeebee: { reportTaskPrototypeList } }) => reportTaskPrototypeList)
);
export const useFindReportTaskPrototypeByID = (reportTaskPrototypeID: number) => (
  useSelector<IStorage, ReportTaskPrototypeDto | null>(({ cdeebee: { reportTaskPrototypeList } }) => (reportTaskPrototypeList && reportTaskPrototypeID) ? reportTaskPrototypeList[reportTaskPrototypeID] : null)
);
// [[#end]] Reports

export const useSegmentList = () => (
  useSelector<IStorage, IInObjectDto<SegmentDto>>(({ cdeebee: { segmentList } }) => segmentList)
);

// MLearnTask
export const useMLearnTaskList = () => (
  useSelector<IStorage, IInObjectDto<MLearnTaskDto>>(({ cdeebee: { mLearnTaskList } }) => mLearnTaskList)
);
export const useFindMLearnTaskByID = (mLearnTaskID: number | undefined) => (
  useSelector<IStorage, MLearnTaskDto | null>(({ cdeebee: { mLearnTaskList } }) => (mLearnTaskList && mLearnTaskID) ? mLearnTaskList[mLearnTaskID] : null)
);
// [[#end]] MLearnTask

// Predictor
export const useFindPredictorModelByModelIDList = (predictorModelIDList: number[]) => (
  useSelector<IStorage, PredictorModelDto[]>(({ cdeebee: { predictorModelList } }) => Object.values(predictorModelList).filter(q => predictorModelIDList.includes(q.predictorModelID)))
);
export const useFindPredictorModelByID = (predictorModelID: number) => (
  useSelector<IStorage, PredictorModelDto | null>(({ cdeebee: { predictorModelList } }) => predictorModelID ? predictorModelList[predictorModelID] : null)
);
export const usePredictorModelList = () => (
  useSelector<IStorage, IInObjectDto<PredictorModelDto>>(({ cdeebee: { predictorModelList } }) => predictorModelList)
);
// [[#end]] Predictor

// Exchange
export const useExchangeList = () => (
  useSelector<IStorage, IInObjectDto<ExchangeDto>>(({ cdeebee: { exchangeList } }) => exchangeList)
);
export const useExchangeLossReasonList = () => (
  useSelector<IStorage, IInObjectDto<ExchangeLossReasonDto>>(({ cdeebee: { exchangeLossReasonList } }) => exchangeLossReasonList)
);
// [[#end]] Exchange

// Reject
export const useRejectList = () => (
  useSelector<IStorage, IInObjectDto<RejectDto>>(({ cdeebee: { rejectList } }) => rejectList)
);
// [[#end]] Reject

// iabCategoryList
export const useIABCategoryList = () => (
  useSelector<IStorage, IInObjectDto<IabCategoryDto>>(({ cdeebee: { iabCategoryList } }) => iabCategoryList)
);
// [[#end]] iabCategoryList

// countryList
export const useCountryList = () => (
  useSelector<IStorage, IInObjectDto<CountryDto>>(({ cdeebee: { countryList } }) => countryList)
);
// [[#end]] countryList

// useLanguageList
export const useLanguageList = () => (
  useSelector<IStorage, IInObjectDto<LanguageDto>>(({ cdeebee: { languageList } }) => languageList)
);
// [[#end]] useLanguageList

// sspList
export const useSspList = () => (
  useSelector<IStorage, IInObjectDto<SspDto>>(({ cdeebee: { sspList } }) => sspList)
);
// [[#end]] sspList

// trackerList
export const useTrackerList = () => (
  useSelector<IStorage, IInObjectDto<TrackerDto>>(({ cdeebee: { trackerList } }) => trackerList)
);
export const useFindTracker = (trackerID: number) => (
  useSelector<IStorage, TrackerDto | null>(({ cdeebee: { trackerList } }) => trackerList?.[trackerID])
);
// [[#end]] trackerList

// Bundle list
export const useBundleList = () => (
  useSelector<IStorage, IInObjectDto<BundleDto>>(({ cdeebee: { bundleList } }) => bundleList)
);
export const useFindBundleByReklBundleID = (reklBundleID: number | undefined) => (
  useSelector<IStorage, BundleDto | null>(({ cdeebee: { reklBundleList, bundleList } }) => (
    ((reklBundleList && reklBundleID) && reklBundleList[reklBundleID] && bundleList[reklBundleList[reklBundleID].bundleID]) || null
  ))
);
export const useFindBundleByID = (bundleID: number | undefined) => (
  useSelector<IStorage, BundleDto | null>(({ cdeebee: { bundleList } }) => bundleID ? bundleList?.[bundleID] : null)
);
// [[#end]]

// Rekl Bundle
export const useReklBundleList = () => (
  useSelector<IStorage, IInObjectDto<ReklBundleDto>>(({ cdeebee: { reklBundleList } }) => reklBundleList)
);
export const useFindReklBundleByID = (reklBundleID: number | undefined) => (
  useSelector<IStorage, ReklBundleDto | null>(({ cdeebee: { reklBundleList } }) => reklBundleID ? reklBundleList?.[reklBundleID] : null)
);
// [[#end]]

// Genre List
export const useGenreList = () => (
  useSelector<IStorage, IInObjectDto<GenreDto>>(({ cdeebee: { genreList } }) => genreList)
);
// [[#end]]
