import { EnumInfoType } from 'constants/enum';
import API from 'constants/api';

export enum EnumResponseStatus {
  UnknownError = -1,
  CouldNotParseInput = -2,

  Success = 0,

  AuthError = 5,
  Forbidden = 6,
  NewVersion = 7,
  ReloadThePage = 8,

  ValidationError = 10,
  JSONParsingError = 11,

  EmailAlreadyRegistered = 101,
  InvalidToken = 151,
  InvalidPassword = 152,
  UserNotFound = 155,
  InvalidSessionVerificationCode = 157,
  SessionVerificationExpired = 159,

  AppGroupNotFound = 50,

  ProductNotFound = 400,
  DuplicateProduct = 401,

  MarkupNotFound = 850,
  SegmentInWrongStateForRefetch = 801,

  ReklEmailNotRekl = 901,

  CampaignCreativeIncorectRelation = 1100,
}

export const ErrorAnywayShow = {
  [API.creativeCopyRequestDto]: {
    text: 'Failed to copy creative',
    view: 'hard',
  },
  [API.campaignCopyRequestDto]: {
    text: 'Failed to copy campaign',
    view: 'hard',
  },
  [API.markupCopyRequestDto]: {
    text: 'Failed to copy markup',
    view: 'hard',
  },
  [API.campaignChangeSetRequestDto]: {
    text: 'Failed to change campaign',
    view: 'hard',
  },
};

export const ErrorLocalize = {
  [EnumResponseStatus.SessionVerificationExpired]: {
    text: 'Verification token has expired',
    view: 'inline'
  },
  [EnumResponseStatus.InvalidSessionVerificationCode]: {
    text: 'Invalid session verification code',
    view: 'inline',
  },
  [EnumResponseStatus.ValidationError]: {
    text: 'Validation error',
    view: 'inline',
  },
  [EnumResponseStatus.ReloadThePage]: {
    text: 'New version is available. Please reload the page using cmd/ctrl+shift+r',
    view: 'inline',
  },
  [EnumResponseStatus.AppGroupNotFound]: {
    text: 'Application group not found',
    view: 'hard',
  },
  [EnumResponseStatus.JSONParsingError]: {
    text: 'Invalid data format',
    view: 'hard',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.SegmentInWrongStateForRefetch]: {
    text: 'Segment in wrong state for refetch',
    view: 'hard',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.ReklEmailNotRekl]: {
    text: 'Incorrect advertiser email',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.CampaignCreativeIncorectRelation]: {
    text: 'Incorrect relation between campaign and creative',
    view: 'hard',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.DuplicateProduct]: {
    text: 'There is duplicate Product for advertiser and bundle. Please update existing',
    view: 'inline',
  },
  [EnumResponseStatus.ProductNotFound]: {
    text: 'Product not found',
    view: 'inline',
  },
  [EnumResponseStatus.EmailAlreadyRegistered]: {
    text: 'Email already registered',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.InvalidPassword]: {
    text: 'Invalid Password',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.AuthError]: {
    text: 'login or password is incorrect',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.NewVersion]: {
    text: 'Update failed. New version is in the system. Please reload the page',
    view: 'inline',
    type: EnumInfoType.warning,
  },
  [EnumResponseStatus.UnknownError]: {
    text: 'Server error',
    view: 'hard',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.CouldNotParseInput]: {
    text: 'Parse value error',
    view: 'hard',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.UserNotFound]: {
    text: 'User not found',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.Forbidden]: {
    text: 'Forbidden',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.InvalidToken]: {
    text: 'Invalid token',
    view: 'inline',
    type: EnumInfoType.error,
  },
  [EnumResponseStatus.MarkupNotFound]: {
    text: 'Markup not found',
    view: 'inline',
  },
};
